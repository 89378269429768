import React, { useState } from 'react'

import classes from './FallingDropdownMenu.module.css'
import './FallingDropdownMenu.css'
import DropdownMenu from '../DropdownMenu/DropdownMenu'

const FallingDropdownMenu = (props) => {
    const [ open, setOpen ] = useState(false)

    return ( 
        <li>
            <div id="dd_menu" className={classes.profilePic} onClick={() => setOpen(!open)} >
                <img src={props.icon} alt="Log in icon of a person" />
            </div>
            {open ? <DropdownMenu collapse={() => setOpen(!open)} /> : null}
        </li>
        )
}

export default FallingDropdownMenu
