import React from 'react'

// css imports
import styles from './ProfileCivilStatus.module.css'

export const ProfileCivilStatus = (props) => {
    const { user } = props

    let interestsConcat = ''
    if(user.interests) {
        user.interests.forEach((interest, i) => {
            if (i === 0) {
                interestsConcat += interest
            } else if (i === user.interests.length - 1) {
                interestsConcat += ' and ' + interest
            } else {
                interestsConcat += ', ' + interest
            }
        })
    }

    return (
        <div className={styles.profileCivilStatus_wrapper}>
            <h1 className={styles.username}>{user.username}</h1>
            <div className={styles.civil_status}>
                <p>Works at {user.workplace ? user.workplace : <span >:</span>}</p>
                <p>Lives in {user.location ? user.location : <span >:</span>}</p>
                <p>Likes {interestsConcat ? interestsConcat : <span >:</span>}</p>
            </div>
        </div>
    )
}

export default ProfileCivilStatus

