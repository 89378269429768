import React from 'react'
import { Link } from 'react-router-dom'
import classes from './Sidebar.module.css'

/** 
 *  Renders a sidebar
 * @param {object} - Props for the comonent
 * @returns {object} - A JSX object
*/
const sidebar = (props) => {
    const { action, newItem, items  } = props

    const list = items.map( item => {
        return (<li key={item.title}><Link to={item.url} className={classes.link}>{item.title}</Link></li>)
    })
    
    return (
        <div className={classes.wrapper}>
            <p onClick={action} className={classes.newCollection}>{newItem.title}</p>
            <ul>
                {list}
            </ul>
        </div>
    )

}

/** 
 * Expose the component
*/
export default sidebar
