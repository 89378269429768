import React, { useState } from 'react'

import fetchApi from '../../../Helpers/fetchApi'
import styles from './CreateCollection.module.css'

/**
 * Renders a form for creating a new collection.
 * @param {object} props - Properties for the component 
 * @returns - JSX
 */
const CreateCollection = (props) => {
    // declare variables
    const { closeOnSuccess } = props
    const [ collectionData, setCollectionData ] = useState({title: '', description: ''})
    const [ display, setDisplay ] = useState('form')
    let toRender = null

    /** 
     * Creates a collection based on user input.
     * 
     * @param {object} event - The event that triggered the functioncall 
     */
    const createCollectionHandler = async (event) => {
        event.preventDefault()
        try {
            const respData = await fetchApi.post.createCollection(collectionData)
            if (respData.status === 'success') {
                setDisplay('success')
            } else {
                setDisplay('fail') 
            }
        } catch (error) {
            setDisplay('fail')            
        }
    }

    /** 
     * Reads user input and saves to state
     * @param {object} event - The event that triggered the functioncall.
    */
    const onChagedHandler = (event) => {
        if (event.target.id === 'collection_title') {
            setCollectionData({title: event.target.value, description: collectionData.description})
        } else {
            setCollectionData({title: collectionData.title, description: event.target.value})
        }
    }

    if (display === 'form') {
        toRender = (
            <div className={styles.mainWrapper}>
              
                <h2 className={styles.header}>Create collection</h2>
                <form onSubmit={createCollectionHandler} className={styles.formElement}>
                    <input id="collection_title" type="text" name="title" placeholder="Name your collection" onChange={onChagedHandler} className={styles.inputfield} />
                    <textarea id="collection_description" name="description"  placeholder="Write description here..." onChange={onChagedHandler} className={styles.description}></textarea>
                    <input type="submit" value="submit" className={styles.button}/>
                </form>
            </div>
            )
    } else if (display === 'fail') {
        toRender = (
            <div className={styles.mainWrapper}>
                <h2 className={styles.header}>Create collection</h2>
                <p className={styles.warning}>Collection could not be created, please try again</p>
                <form onSubmit={createCollectionHandler} className={styles.formElement}>
                    <input id="collection_title" type="text" name="title" placeholder="Name your collection" onChange={onChagedHandler} className={styles.inputfield} />
                    <textarea id="collection_description" name="description"  placeholder="Write description here..." onChange={onChagedHandler} className={styles.description}></textarea>
                    <input type="submit" value="submit" className={styles.button}/>
                </form>
            </div>
        )
    } else if (display === 'success') {
        toRender = (
            <div className={styles.successWrapper}>
                <h2 className={styles.success}>You have a new collection</h2>
                <p className={styles.successMessage}>Your collection was created. Fill it with something awesome!</p>
                <a href="/collections" onClick={closeOnSuccess} className={styles.successBtn}>Close</a>
            </div>
        )
    }

    
    return (
            <div> 
                {toRender}
            </div>
            )
}

/** 
 * Expose component
*/
export default CreateCollection
