import React from 'react'

// css imports
import styles from './ProfileAccountStatus.module.css'

const ProfileAccountStatus = (props) => {
    const { user } = props

    return (
        <div className={styles.profileAccountStatus_wrapper}>
            <h2 className={styles.header}>You have</h2>
            <div className={styles.account_status}>
                <p>{user.collections} collections</p>
                <p>{user.snapshots} snapshots</p>
                <p>{user.items} items</p>
                {user.topStore ? <p>and {user.topStore} is your top store</p> : null}
            </div>
        </div>
    )
}

export default ProfileAccountStatus

