import React, { Component } from 'react'
import styles from './Popup.module.css'

/**
 * Displays a popup on the current screen. Use with JSX or a custom component as child(ren) to fill the popup window with content.
 * Takes the props: action: onClickhander for the button in top left, actionButtonName: Button text.
 * @class Popup
 * @extends {Component}
 */
class Popup extends Component {
    constructor(props) {
        super(props)
        this.state = { }
    }

    render () {
        const { action, actionButtonName } = this.props
        return (
            <div className={styles.pu_background} >
                <div className={styles.pu_window} >
                    <div className={styles.pu_topbar}><div onClick={action} className={styles.pu_closeBtn} >{actionButtonName}</div></div>  
                    <div className={styles.children_wrapper}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )

    }
}

export default Popup
