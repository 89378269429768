import React, { Component } from 'react'

// import custom components
import styles from './Items.module.css'
import Item from '../Item/Item'
import Popup from '../../../Popup/Popup'
import StandardGuide from '../../../InformationProviders/Guides/StandardGuide/StandardGuide'

// import helpers
import fetchApi from '../../../Helpers/fetchApi'


/**
 * Renders a collection of items.
 *
 * @export
 * @class Items
 * @extends {Component}
 */
export default class Items extends Component {
  constructor(props) {
    super(props)
    this.state = {
        items: [],
        collectionTitle: null,
        isLoading: false,
        showDeletePopup: false,
        deleteItemId: null,
        deleteItemTitle: null,
        showEditPopup: false,
        itemToEdit: {
          id: null,
          title: null,
          description: null,
          image: null
        },
        errorOnEdit: false
      }

    // bind methods
    this.deletePopupHandler = this.deletePopupHandler.bind(this)
    this.editPopupHandler = this.editPopupHandler.bind(this)
    this.deleteItemHandler = this.deleteItemHandler.bind(this)
  }

  /**
  * Function called each time the component mounts on the page.
  *
  * @memberof Items
  */
  async componentDidMount() {
    const { collectionId } = this.props

    this.setState({isLoading: true})
      try {
        const responseData = await fetchApi.get.collection(collectionId)
        this.setState({ items: await responseData.data.items, collectionTitle: await responseData.data.collectionTitle, isLoading: false}) 
        this.props.onCurrentCollectionChange(await responseData.data.collectionTitle)
      } catch (error) {
        console.error('Error')
      } 
  }

  /**
   * Updates the state with a new item
   *
   * @memberof Items
   * @param {object} item - An item.
   */
  updateStateWithItem = (item) => {
    const allItems = this.state.items.slice()
    allItems.push(item)

    this.setState({items: allItems})
  }

  /**
   * Handles the statechange when choosing to delete an item.
   *
   * @memberof Items
   * @param {string} itemData - Contains id and title of the item to be deleted.
   */
  deletePopupHandler = (itemData) => {
    const { id, title }  = itemData

    const newState = {
        ...this.state,
        showDeletePopup: !this.state.showDeletePopup,
        deleteItemId: id,
        deleteItemTitle: title,
    }
    this.setState(newState)
  }
  
  /**
   * Handles the statechange when choosing to edit an item.
   *
   * @memberof Items
   * @param {string} itemData - Contains id and title of the item to be deleted.
   */
  editPopupHandler = (itemData) => {
    const { id, title, description, image }  = itemData

    const newState = {
        ...this.state,
        showEditPopup: !this.state.showEditPopup,
        itemToEdit: {
          id: id,
          title: title,
          description: description,
          image: image
        },
        errorOnEdit: false
    }
    this.setState(newState)
  }
  
  /** 
   * Handles the call to the server when removing an item.
  */
  deleteItemHandler = async () => {
    try {
      const responseData = await fetchApi.post.deleteItem(this.state.deleteItemId)

      if(responseData.status === 'success') {
        const itemsCopy = this.state.items
        const reducedItems = itemsCopy.filter(item => item._id !== this.state.deleteItemId)

        const newState = {
          ...this.state,
          items: reducedItems,
          showDeletePopup: !this.state.showDeletePopup,
          deleteItemId: null,
          deleteItemTitle: null
        }
        this.setState(newState)
      }
      
    } catch (error) {
      //display errror in popup
    }
  }

  /**
   * Handles the call to the server when editing an item and sets the state with the edited item.
   *
   * @param {object} e - The event that triggered the function call
   * @memberof Items
   */
  editItemHandler = async (e) => {
    e.preventDefault()
    const responseData = await fetchApi.post.editItem(this.state.itemToEdit)

    if (responseData.status === 'success') {
      this.editPopupHandler(this.state.itemToEdit)

      const itemsCopy = [...this.state.items]
      itemsCopy.forEach(item => {
        if (item._id === this.state.itemToEdit.id) {
          item.title = this.state.itemToEdit.title
          item.description = this.state.itemToEdit.description
        }
      })
      
      this.setState({
        ...this.state,
        items: itemsCopy
      })
    } else {
     this.setState({
       ...this.state,
       errorOnEdit: true
     })
    }
  }

  /**
   * Handles the change of the state for the item to be edited.
   * 
   * @param {object} e - The event that triggered the function call.
   * @memberof Items
   */
  onEditChangedHandler = (e) => {
    const targetValue = e.target.value
    const targetName = e.target.name

    const newEditItemState = {
      ...this.state.itemToEdit
    }

    newEditItemState[targetName] = targetValue
    
    this.setState({
      ...this.state,
      itemToEdit: newEditItemState
    })
  }

  render() {
    // check conditions before render
    let popup = null
    if (this.state.showDeletePopup) {
      popup = <Popup action={this.deletePopupHandler} actionButtonName="Close">
        <div className={styles.popupWrapper}>
          <h2>Delete "{this.state.deleteItemTitle}"?</h2>
          <button onClick={this.deleteItemHandler} className="redBtn">Yes!</button>
        </div>
      </Popup>
    } else if (this.state.showEditPopup) {
      popup = <Popup action={this.editPopupHandler} actionButtonName="Close">
      <div className={styles.edit_popup_wrapper}>
        <div className={styles.edit_header_wrapper}>
          <h2>Edit product</h2>
          {this.state.errorOnEdit ? <p className="error_message_small">Something went wrong when saving changes, prease try again</p> : null} 
        </div>
        
        <div className={styles.edit_content}>
          <form onSubmit={this.editItemHandler} className={styles.edit_item_form}>
            <label >Title</label>
            <input type="text" name="title" id="title" onChange={this.onEditChangedHandler} value={this.state.itemToEdit.title} className={styles.edit_title}/>
            <label >Description</label>
            <textarea name="description" id="description" onChange={this.onEditChangedHandler} value={this.state.itemToEdit.description} className={styles.edit_description}></textarea>
            <input type="submit" className="redBtn" value="Save" />
          </form>
          <div className={styles.edit_image_wrapper}>
            {this.state.itemToEdit.image !== 'not found' ? <img src={this.state.itemToEdit.image} className={styles.image} /> : <p className={styles.no_image}>We could not find an image for this product</p>}
          </div>
        </div>
      </div>
    </Popup>
    }

    // if loading
    if (this.state.isLoading) {
      return <p className="loading"> Items loading... </p>
    }

    
    
    
    let collection = null
    if (this.state.items?.length > 0) {
        collection = this.state.items.map(item => {
            return <Item key={item._id} _id={item._id} title={item.title} description={item.description} image={item.image} url={item.url} onDelete={this.deletePopupHandler} onEdit={this.editPopupHandler} />
        })
    } else {
      const content = {
        header: 'Add a new product!',
        preamble: 'Looks like you do not have any products yet. Add a new product by clicking the "Add product"-button.'
      }

      const falldownContent = {
        header: 'What is this?',
        bread: 'This is where you will store all your products. You can add products either with a webbadress to the product or via the web extension',
        descriptive: 'When clicking "Add product" you will be able to provide a webb adress to the product you want to save. Simply copy the adress from the adressbar for the product you want to save and paste it in the inputfield. A better way is to download the browser extension and add your products from there. Its both easier and faster.'
      }

      collection = <StandardGuide content={content} falldownContent={falldownContent}/>
    }



    return (
        <div className={styles.wrapper}>
          {popup}
          {collection}
        </div>
    )
  }
}
