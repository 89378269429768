import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'


import OverlayMenu from '../../../menus/OverlayMenu/OverlayMenu'

import styles from './Collection.module.css'

const Collection = (props) => {
    const { _id, title, description, onDelete, onEdit } = props
    const [showMenu, setShowMenu] = useState(false)
    const typeInfo = {
        id: _id,
        title: title,
        description: description
    }

    return (
        <div className={styles.mainWrapper} onMouseEnter={() => { setShowMenu(true)}} onMouseLeave={() => { setShowMenu(false)}}>
            <div id={_id} className={styles.collectionCardWrapper}>
                <div className={styles.menuSection}>
                    <CSSTransition 
                    in={ showMenu === true}
                    unmountOnExit
                    timeout={500}
                    classNames="overlayMenu-fade"
                    >
                        <div className="menu" >
                            <OverlayMenu type="collection" typeInfo={typeInfo} collectionId={_id} collectionTitle={title} collectionDescription={description} onDelete={onDelete} onEdit={onEdit} />
                        </div>
                    </CSSTransition>
                </div>

                <Link to={'/collections/' + _id + '/view-collection'} className={styles.link}>   
                    <div className={styles.header}>
                        <h2>{title}</h2>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Collection
