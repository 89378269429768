import React, { Component } from 'react'

import styles from './OverlayMenu.module.css'

/**
 *  Renders an overlay menu on the component with edit and delete functionality
 * 
 *  @param {object} props - takes type (type of component), typeInfo (information about the component), onEdit (edit function: takes the typeInfo as param ), onDelete (delete function: takes the typeInfo as param)
 */
class OverlayMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toRemove: [],
            toEdit: null,
            type: this.props.type
        }
        this.clickHandler = this.clickHandler.bind(this)
    }

    clickHandler = (event) => {
        event.target.textContent === 'Delete' ? this.props.onDelete(this.props.typeInfo) : this.props.onEdit(this.props.typeInfo)  
    
    }

    render () {
        return (
            <div className={styles.wrapper}>
                <div className={styles.menuBar}>
                    <div  className={styles.menuItemsWrapper} onClick={this.clickHandler}>
                        <span>Edit</span>
                        <span>Delete</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default OverlayMenu
