import React, {useState} from 'react'
import { useParams } from "react-router";

// import styles
import styles from './ItemsView.module.css'

// import custom components
import Items from '../Items/Items'
import DisplayNavigation from '../../DisplayNavigation/DisplayNavigation'
import Sidebar from '../../../menus/Sidebar/Sidebar'
import Popup from '../../../Popup/Popup'
import AddItem from '../AddItem/AddItem'

/**
 * Renders the view over all items in a collection and a sidebar related to the items.
 * 
 * @param {object} props - Properties for the component.
 * @returns {object} - The component.
 */
const ItemsView = (props) => {
    const { id } = useParams()
    const [ currentCollection, setCurrentCollection ] = useState()
    const [ showCreateItemPopup, setShowCreateItemPopup ] = useState(false)
    let popup = null

    // add this when functionality is implemented
    const sidebarLinks = [
        // { url: '/collections/:id/view-collection', title: 'Organize'},
        // { url: '/collections/:id/view-collection', title: 'Edit'},
        // { url: '/collections/:id/view-collection', title: 'Remove'},
    ]
    
    /**
     * Updates the display navigation panel to show witch collection is currently viewed.
     * 
     * @param {string} current - What collection the DisplayNavigation will show. 
     */
    const currentCollectionHandler = (current) => {
        setCurrentCollection(current)
    }

    /**
     * Handles the show/hide of the create item popup
     */
    const createItemPopupHandler = () => {
        setShowCreateItemPopup(!showCreateItemPopup)
    }

    // conditions before returning the component
    if (showCreateItemPopup) {
        popup = <Popup action={createItemPopupHandler} actionButtonName="Close"><AddItem collectionId={id} closePopup={createItemPopupHandler} /></Popup>
    }


    // return component
    return (
        <div className={styles.wrapper}>
            {popup}
            <Sidebar items={sidebarLinks} action={createItemPopupHandler} newItem={{title: 'Add product'}} />
            <div className={styles.centerWrapper}>
                <DisplayNavigation collection={currentCollection} collectionId={id} />
                <Items collectionId={id} onCurrentCollectionChange={currentCollectionHandler} />
                {/* <Items collectionId={id} onCurrentCollectionChange={currentCollectionHandler} newItem={onNewItem}/> */}
            </div>
        </div>
    )
}

/** 
 * Expose component
*/
export default ItemsView
