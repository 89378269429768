import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// import custom components
import FalldownInformation from '../../InformationProviders/FalldownInformation/FalldownInformation'

// import style
import styles from './ExtendedExperienceInstallation.module.css'

/**
 *  Renders a guide to install browser extension.
 * 
 * @returns {JSX} - The JSX to be rendered to the DOM.
 */
const ExtendedExperienceInstallation = () => { 

    // FalldownInformation content
    const content = {
        header: 'How to activate',
        bread: 'The extension is completely free and activated through Chrome web store, which is the official chrome store for downloading chrome related products, including browser extensions.',
        descriptive: 'Click the button below which will take you to the Chrome web store. Activate the extension by clicking “Add to Chrome”. There will be a popup that asks for permissions to https://shopr-app.com, this is Chromes way of notifying you that the extension will be able to communicate with your Shop’r account. Accept the terms and start saving products to your collections by clicking the newly added red “S”-icon in your browser window.'
        
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.header_wrapper}>
                <h1 className={styles.header_text}>Shop'r extended experience</h1>
                <div className={styles.bottom_line}></div>
                <p className={styles.bread_text}>Shop'r extended experience is a "must-have". It makes saving prducts to your collections much easier.
                    The extended experience consist of a free browser extension that lets you add products to your account. So when you browse the web and find a product that you like on a webstore you can save it to one of your collections with just a few clicks.</p>
            </div>

            <div className={styles.content_wrapper}>
                <FalldownInformation content={content} />
                <button className={styles.activate_chrome_button}><a href="https://chrome.google.com/webstore/detail/shopr-for-chrome/binjjddffjdkggkfhggadganjohdgmcd/related" rel="noopener noreferrer" target="_blank">Activate on Chrome</a></button>
            </div>
        </div>
    )
}

export default ExtendedExperienceInstallation
