import React, { Component } from 'react'
import { Route } from 'react-router-dom'

// import helpers
import fetchApi from '../Helpers/fetchApi'

// import styles
import styles from './CenterContent.module.css'

// import custom components
import Popup from '../Popup/Popup'
import CollectionManager from '../CollectionManager/CollectionManager'
import ChangeProfile from '../Profile/ChangeProfile/ChangeProfile/ChangeProfile'
import CreateCollection from '../CollectionManager/Collections/CreateCollection/CreateCollection'
import AccountRegistration from '../Forms/AccountRegistration/AccountRegistrationForm'
import Authenticate from '../Forms/Authenticate/Authenticate'
import ViewProfile from '../Profile/ViewProfile/ViewProfile/ViewProfile'
import Logout from '../Logout/Logout'
import NewsWrapper from '../News/NewsWrapper/NewsWrapper'
import ExtendedExperienceInstallation from '../ExtendedExperience/ExtendedExperienceInstallation/ExtendedExperienceInstallation'

class CenterContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showExtensionPopup: !this.props.userDetails.recievedExtensionInfo,
      checkbox: null
    }
     //bind methods
     this.showHideExtensionPopup = this.showHideExtensionPopup.bind(this)  
  }


  showHideExtensionPopup = () => {
    this.setState({
      ...this.state,
      showExtensionPopup: !this.state.showExtensionPopup
    })
  }

  checkboxHandler = (e) => {
    this.setState({
      ...this.state,
      checkbox: e.target.checked
    })
  }

  onSubmitHandler = async (e) => {
    e.preventDefault()
    this.showHideExtensionPopup()

    if (this.state.checkbox) {
      const respData = await fetchApi.post.recievedExtensionInfo()
      this.setState({
        ...this.state,
        showHideExtensionPopup: false
      })
    }
    
  }

  render() {
    let showExtensionInfo = null
    if (!this.props.userDetails.recievedExtensionInfo) {
      showExtensionInfo = (
        <Popup action={this.showHideExtensionPopup} actionButtonName="close">
            <ExtendedExperienceInstallation />
            <form onSubmit={this.onSubmitHandler} className={styles.formElement}>
              <div className={styles.checkbox_wrapper}>
                <label for="dontShowAgainCheckbox" className={styles.label}>Don't show this message again</label>
                <input onChange={this.checkboxHandler} type="checkbox" id="dontShowAgainCheckbox"/>
              </div>
              <input type="submit" value="Ok, I got it!" className={styles.button}/>
            </form>
        </Popup>
      )
    }

    return (
        <div className={styles.CenterContent}>
          {this.state.showExtensionPopup ? showExtensionInfo : null}
          <Route path="/collections" component={CollectionManager}/>
          <Route exact path="/create-collection" component={CreateCollection}/>
          <Route exact path="/signin" component={Authenticate}/>
          <Route exact path="/registration" component={AccountRegistration}/>
          <Route exact path="/profile" component={ViewProfile}/>
          <Route exact path="/change-profile" component={ChangeProfile}/>
          <Route exact path="/logout" component={Logout}/>
          <Route exact path="/extended-experience-activation" component={ExtendedExperienceInstallation}/>
          <Route exact path="/" component={CollectionManager}/>
        </div>
    )
  }
}

export default CenterContent
