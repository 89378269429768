import React from 'react'

import styles from './GreetingBackgroundCard.module.css'

/**
 * Creates a card containing an image.
 * 
 * @param {object} props - Properties for the component 
 */
const GreetingBackgroundCard = (props) => {
    const { imageURL } = props

    return (
            <div className={styles.card}>
                <img src={imageURL} alt="Things to by even on a rainy day" />
            </div>
    )
}

/** 
 * Expose component
*/
export default GreetingBackgroundCard
