import React from 'react'

// import classes from './DropdownMenu.module.css'

const DropdownMenuItem = (props) => {

    return (
        <div className="dd_menu-item" onClick={() => { props.change(props.goToMenu) }}>
            <span>{props.leftIcon}</span>
            {props.children}
            <span className="icon-right">{props.rightIcon}</span>
        </div>
    )

}

export default DropdownMenuItem

