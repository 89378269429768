import React, { useState }  from 'react'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import DropdownMenuSection from '../DropdownMenuSection/DropdownMenuSection'
// import Popup from '../../../Popup/Popup'
import './DropdownMenu.css'

/**
 * Displays a dropdown menu with multiple menus inside.
 * 
 * @returns {object} - JSX object representing the menu.
 */
const DropdownMenu = (props) => {
    const { collapse } = props
    const [activeMenu, setActiveMenu] = useState('main')
    const [menuHeight, setMenuHeight] = useState(300)

    /**
     * Sets the menu that will be shown.
     * @param {string} - Name of the menu.
     */
    const changeState = (value) => {
        setActiveMenu(value)
    }

    /**
     * Sets the height of the menu.
     * @param {DOMElement} - The menu-element
     */
    const calcHeight = (element) => {
        const height = element.offsetHeight + 50
        setMenuHeight(height)
    }

    // the content of the main menu in the dropdown
    const mainMenu =[
        { key: 1, goToMenu: 'profile', title: 'Profile', url: null },
        { key: 2, goToMenu: null, title: 'My collections', url: '/collections' },
        { key: 3, goToMenu: 'extendedExperience', title: 'Extended experience', url: null },
        { key: 4, goToMenu: 'logout', title: 'Sign out', url: null }
    ]

    // content of the Profile menu
    const profileMenu = [
        { key: 1, goToMenu: 'main', title: 'Back', url: null},
        { key: 2, goToMenu: null, title: 'View profile', url: '/profile'},
        { key: 3, goToMenu: null, title: 'Change profile', url: '/change-profile'},
        // { key: 4, goToMenu: null, title: 'Private settings', url: '/private-settings'}
    ]

    // content of Extended experience menu
    const extendedExperienceMenu = [
        { key: 1, goToMenu: 'main', title: 'Back', url: null},
        { key: 2, goToMenu: null, title: 'Extend your experience', url: '/extended-experience-activation'},
        { key: 3, goToMenu: null, title: 'What is this?', url: '/extended-experience-activation'}
    ]

    const logoutMenu = [
        { key: 1, goToMenu: 'main', title: 'Back', url: null},
        { key: 2, goToMenu: null, title: 'Sign me out, please!', url: '/logout'}
    ]

    return (
        <div className="dd_wrapper" style={{height: menuHeight}}>
            <CSSTransition 
            in={ activeMenu === 'main'}
            unmountOnExit
            timeout={500}
            classNames="menu-slideToLeft"
            onEnter={calcHeight}
            >
                <div className="menu">
                    <DropdownMenuSection menu={mainMenu} changeState={changeState} collapse={collapse} />
                </div>
            </CSSTransition>

            <CSSTransition 
            in={ activeMenu === 'profile'}
            unmountOnExit
            timeout={500}
            classNames="menu-third"
            onEnter={calcHeight}
            >
                <div className="menu">
                    <DropdownMenuSection menu={profileMenu} changeState={changeState} collapse={collapse} />
                </div>
            </CSSTransition>

            <CSSTransition 
            in={ activeMenu === 'extendedExperience'}
            unmountOnExit
            timeout={500}
            classNames="menu-third"
            onEnter={calcHeight}
            >
                <div className="menu">
                    <DropdownMenuSection menu={extendedExperienceMenu} changeState={changeState} collapse={collapse} />
                </div>
            </CSSTransition>

            <CSSTransition 
            in={ activeMenu === 'logout'}
            unmountOnExit
            timeout={500}
            classNames="menu-third"
            onEnter={calcHeight}
            >
                <div className="menu">
                    <DropdownMenuSection menu={logoutMenu} changeState={changeState} collapse={collapse} />
                </div>
            </CSSTransition>
        </div>
    )

}

/** 
 *  Expose component
*/
export default DropdownMenu
