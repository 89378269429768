import * as actionTypes from './actionTypes'

import fetchApi from '../../Helpers/fetchApi'

// action creators
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (authData) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        authData: authData
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logOut = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const logout = () => {
    return dispatch => {
        fetchApi.post.logOut().then(resp => dispatch(logOut())).catch(resp => dispatch(logOut()))
    }

}

// authentication logic
export const auth = (username, passphrase) => {
    return dispatch => {
        dispatch(authStart())

        const authData = {
            username: username,
            passphrase: passphrase
        }

        fetchApi.post.authenticate(authData).then(response => {
 
            if(response.error) {
                return dispatch(authFail(response.error))
            }

            window.localStorage.setItem('JWT', response.token)
            dispatch(authSuccess(response))
        }).catch(error => {
            console.error(error)
            dispatch(authFail(error))
        })

    }
}

export const checkAuthentication = () => {

    return dispatch => {
        const token = window.localStorage.getItem('JWT')
        
        if (!token) {
            return
        }

        fetchApi.post.checkToken(token).then(response => { 
            
            dispatch(authSuccess(response))
        } ).catch(error => dispatch(authFail(error)))


    }

    
}
