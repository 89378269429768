import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'

// import styles
import styles from './Item.module.css'

// import custom components
import OverlayMenu from '../../../menus/OverlayMenu/OverlayMenu'

const Item = (props) => {
    // props
    const {_id, title, image, description, url, onDelete, onEdit } = props

    // state hooks
    const [onExpand, setOnExpand ] = useState(true)
    const [showMenu, setShowMenu] = useState(false)

    // variables
    let wrapperStyles = [styles.wrapper]
    let descriptionWrapperStyles = [styles.descriptionWrapper]
    let imageStyles = [styles.image]
    const typeInfo = {
        id: _id,
        title: title,
        description: description,
        image: image
    }

    const changeSizeHander = async () => {
        setOnExpand(!onExpand) 
    }

    if (onExpand) {
        wrapperStyles = [styles.wrapper, styles.small]
        descriptionWrapperStyles = [styles.descriptionWrapper, styles.descriptionSmall]
        imageStyles = [styles.image, styles.imageSmall]
    } else {
        wrapperStyles = [styles.wrapper, styles.large]
        imageStyles = [styles.image, styles.imageLarge]
    }

    return (

            <div id={_id} className={wrapperStyles.join(' ')} onClick={changeSizeHander} onMouseEnter={() => { setShowMenu(true)}} onMouseLeave={() => { setShowMenu(false)}}>
                <div className={styles.menuSection}>
                    <CSSTransition 
                    in={ showMenu === true}
                    unmountOnExit
                    timeout={500}
                    classNames="overlayMenu-fade"
                    >
                        <div className="menu" >
                            <OverlayMenu type="item" typeInfo={typeInfo} collectionId={_id} collectionTitle={title} onDelete={onDelete} onEdit={onEdit} />
                        </div>
                    </CSSTransition>
                </div>
                {image !== 'not found' ? <img src={image} alt="product" className={imageStyles.join(' ')} />: <div className={imageStyles.join(' ')}>No product image</div>}
                <h2 className={styles.title}>{title}</h2>
                <div className={descriptionWrapperStyles.join(' ')}>
                    <div className={styles.description}>{description}</div>
                    <div className={styles.btnWrapper}>
                        <a href={url} target="_blank" rel="noopener noreferrer" className={styles.linkBtn}>Visit site</a>
                    </div>
                </div>
            </div>
    )
}

export default Item
