import React from 'react'
import { Link } from 'react-router-dom'

import styles from './displayNavigation.module.css'

/*** 
 * Shows a navigation bar indicating where the user is located
*/
const displayNavigation = (props) => {
const { collection, collectionId } = props
    return (
        <div className={styles.wrapper}>
            <div className={styles.bottomLine}>
                <Link to="/collections" className={styles.link}>Collections</Link>
                {collection ? <div className={styles.arrow}>></div> : null }
                
                <Link to={'/collections/' + collectionId + '/view-collection'} className={styles.link}>{collection}</Link>
            </div>
        </div>
    )

}

export default displayNavigation

