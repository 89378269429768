import React, { Component } from 'react'

// styles
import styles from './Collections.module.css'

// custom components
import Collection from '../Collection/Collection'
import Popup from '../../../Popup/Popup'
import StandardGuide from '../../../InformationProviders/Guides/StandardGuide/StandardGuide'

//helpers
import fetchApi from '../../../Helpers/fetchApi'

/**
 * Shows all users collections
 *
 * @export
 * @class Collections
 * @extends {Component}
 */
export default class Collections extends Component {
  constructor(props) {
    super(props)
    this.state = {
        collections: [],
        isLoading: false,
        showDeletePopup: false,
        deleteCollectionId: null,
        deleteCollectionTitle: null,
        showEditPopup: false,
        editCollection: {
          id: null,
          title: null,
          description: null
        },
        errorOnEdit: false
      }

      // bind methods
      this.deletePopupHandler = this.deletePopupHandler.bind(this)
      this.deleteCollectionHandler = this.deleteCollectionHandler.bind(this)
      this.editPopupHandler = this.editPopupHandler.bind(this)
  }

  /**
   * Function called each time the component mounts on the page.
   *
   * @memberof Collections
   */
  async componentDidMount() {
    this.setState({isLoading: true})
      try {
        const responseData = await fetchApi.get.collections()
        this.setState({ collections: await responseData.data, isLoading: false}) 
        
      } catch (error) {
        console.log('Could not find any collections')
      } 
  }

  /**
   * Handles the statechange when choosing to delete a collection.
   *
   * @memberof Collections
   * @param {string} collectionId - The id of the collection to be deleted.
   * @param {string} collectionTitle - The title of the collection to delete.
   */
  deletePopupHandler = (collectionInfo) => {
    const { id, title } = collectionInfo

    const newState = {
        ...this.state,
        showDeletePopup: !this.state.showDeletePopup,
        deleteCollectionId: id,
        deleteCollectionTitle: title
    }
    this.setState(newState)
  }
  
  /**
   * Handles the statechange when choosing to edit a collection.
   *
   * @memberof Collections
   * @param {string} collectionId - The id of the collection to be edited.
   * @param {string} collectionTitle - The title of the collection to edited.
   */
  editPopupHandler = (collectionInfo) => {
    const { id, title, description } = collectionInfo

    const newEditCollectionState = {
      ...this.state.editCollection,
      id: id,
      title: title,
      description: description
    }
    const newState = {
        ...this.state,
        editCollection: newEditCollectionState,
        showEditPopup: !this.state.showEditPopup,
        errorOnEdit: false

    }
    this.setState(newState)
  }

  /**
   * Updates the local state with new input from the edit-popup window.
   * 
   * @param {object} e - The event that triggered the function call.
   * @memberof Collections
   */
  onEditChangedHandler = (e) => {
    const targetValue = e.target.value
    const targetName = e.target.name

    const newEditCollectionState = {
      ...this.state.editCollection
    }

    newEditCollectionState[targetName] = targetValue
    
    this.setState({
      ...this.state,
      editCollection: newEditCollectionState
    })
  }
  
  /** 
   * Handles the call to the server when removing a collection..
  */
  deleteCollectionHandler = async () => {
    try {
      const responseData = await fetchApi.post.deleteCollection(this.state.deleteCollectionId)

      if (responseData.status === 'success') {
        const collectionsCopy = this.state.collections
        const reducedCollections = collectionsCopy.filter(collection => collection._id !== this.state.deleteCollectionId)

        const newState = {
          ...this.state,
          collections: reducedCollections,
          showDeletePopup: !this.state.showDeletePopup,
          deleteCollectionId: null,
          deleteCollectionTitle: null
        }
        this.setState(newState)
      }
      
    } catch (error) {
      console.log('Something went wrong');
    }
  }

  /**
   * Sends a edit-collection request to the api and updates current state with new name if everything went well.
   *
   * @memberof Collections
   * @param {object} e - The event that triggered the functioncall.
   */
  editCollectionHandler = async (e) => {
    e.preventDefault()
    const responseData = await fetchApi.post.editCollection(this.state.editCollection)

    if (responseData.status === 'success') {
      this.editPopupHandler(this.state.editCollection)

      const collectionsCopy = [...this.state.collections]
      collectionsCopy.forEach(collection => {
        if (collection._id === this.state.editCollection.id) {
          collection.title = this.state.editCollection.title
          collection.description = this.state.editCollection.description
        }
      })

      this.setState({
        ...this.state,
        collections: collectionsCopy
      })

    } else {
     this.setState({
       ...this.state,
       errorOnEdit: true
     })
    }
  }

  /**
   *  What to render to the DOM.
   * 
   * @returns {JSX} - JSX object to render to the DOM.
   */
  render() {
    // show popup
    let popup = null
    if (this.state.showDeletePopup) {
      popup = <Popup action={this.deletePopupHandler} actionButtonName="Close">
        <div className={styles.delete_popup_wrapper}>
          <h2>Delete "{this.state.deleteCollectionTitle}"?</h2>
          <button onClick={this.deleteCollectionHandler} className="redBtn">Yes!</button>
        </div>
      </Popup>
    } else if (this.state.showEditPopup) {
      popup = <Popup action={this.editPopupHandler} actionButtonName="Close">
        <div className={styles.edit_popup_wrapper}>
          <h2>Edit collection</h2>
          {this.state.errorOnEdit ? <p className="error_message_small">Something went wrong when saving changes, prease try again</p> : null}
          <form onSubmit={this.editCollectionHandler} className={styles.edit_collection_form}>
            <label >Title</label>
            <input type="text" name="title" id="title" onChange={this.onEditChangedHandler} value={this.state.editCollection.title} className={styles.edit_title}/>
            <label >Description</label>
            <textarea name="description" id="description" onChange={this.onEditChangedHandler} value={this.state.editCollection.description} className={styles.edit_description}></textarea>
            <input type="submit" className="redBtn" value="Save" />
          </form>
        </div>
      </Popup>
    }


    let collections = null
    if (this.state.collections?.length > 0) {
      collections = this.state.collections.map(collection => {
        return (
          <Collection key={collection._id} _id={collection._id} title={collection.title} description={collection.description} onDelete={this.deletePopupHandler} onEdit={this.editPopupHandler} />
        )
      })
    } else {
      const content = {
        header: 'Create a new collection!',
        preamble: 'Looks like you do not have any collections yet. Create a new collection by clicking the "New collection"-button.'
      }

      const falldownContent = {
        header: 'What is a collection?',
        bread: 'Collections is where you will store all your products you want to save. You can see your collections like folders where you keep all your products.',
        descriptive: 'You can create, delete and rename your collections at any time. The collections that you create will automatically sync with your web extnsion. As an example: You can create a collection called "Shoes" and when you browse the web and find a pair of shooes that you like you can save them directly through the extension or inside the collection you create.'
      }

      collections = <StandardGuide content={content} falldownContent={falldownContent}/>
    }

    if (this.state.isLoading) {
      return <p className="loading">Collection loading... </p>
    }

    return (
      <div className={styles.wrapper} key={this.state.newCollection}>
        {popup}
        {collections}  
      </div>
    )
  }
}
