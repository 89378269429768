import React, { Component } from 'react'
import { Route , Switch } from 'react-router-dom'

import CollectionsView from './Collections/CollectionsView/CollectionsView'
import ItemsView from './Items/ItemsView/ItemsView'

/**
 * Renders collection specific routes.
 */
export default class CollectionManager extends Component {

  render() {
    return (
        <div >
          <Switch>
            <Route path="/collections/:id/view-collection" component={ItemsView} />
            <Route exact path="/collections" component={CollectionsView} />
            <Route exact path="/" component={CollectionsView} />
          </Switch>
      </div>
    );
  }
}
