import React, { useState } from 'react'

// css imports
import styles from './ChangeProfileSection.module.css'

export const ChangeProfileSection = (props) => {
    const { children, showChangeForm } = props

    return (
        <div className={styles.wrapper}>
            <div className={styles.child_content}>
                {children}
            </div>
            <div className={styles.button_wrapper}>
                <button onClick={showChangeForm} className={styles.editBtn}>Edit</button>
            </div>
        </div>
    )
}

export default ChangeProfileSection

