import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

// initial state
const initialState = {
    isAuthenticated: false,
    username: null,
    email: null,
    token: null,
    firstName: null,
    lastName: null,
    workplace: null,
    location: null,
    interests: null,
    avatar: null,
    recievedExtensionInfo: null,
    error: null,
    loading: false
}

const authStart = (state, action) => {
    return updateObject(state, {error: null, loading: true})
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        isAuthenticated: true, 
        userId: action.authData.data.id,
        username: action.authData.data.username,
        email: action.authData.data.email,
        token: action.authData.data.token,
        firstName:  action.authData.data.firstName,
        lastName:  action.authData.data.lastName,
        workplace:  action.authData.data.workplace,
        location:  action.authData.data.location,
        interests:  action.authData.data.interests,
        avatar:  action.authData.data.avatar,
        recievedExtensionInfo:  action.authData.data.recievedExtensionInfo,
        error: null,
        loading: false})
}

const authFail = (state, action) => {
    return updateObject(state, {
        isAuthenticated: false, 
        username: null,
        email: null,
        token: null,
        firstName: null,
        lastName: null,
        workplace: null,
        location: null,
        interests: null,
        avatar: null,
        recievedExtensionInfo: null,
        error: action.error,
        loading: false})
}

const logout = (state, action) => {
    return updateObject(state, initialState)
}

// Reducer, handles all user-state related logic, listens for dispatched events
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action)
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_FAIL: return authFail(state, action)
        case actionTypes.AUTH_CHECK_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_LOGOUT: return logout(state, action)
        default: return state
    }
}

export default authReducer
