import React from 'react'
import { Link } from 'react-router-dom'

import DropdownMenuItem from '../dropdownMenuItem/DropdownMenuItem'

/** 
 * Renders a menu section in the dropdown menu.
 * 
 * @param {object} props - Properties for the component.
*/
const DropdownMenuSection = (props) => {
    const { changeState, menu, collapse } = props

    const sectionMenu = menu.map(item => {
        let link = null
        item.url ? link = <Link to={item.url} onClick={collapse} className="menuLink">{item.title}</Link> : link = null

    return <DropdownMenuItem key={item.key} goToMenu={item.goToMenu} change={changeState}>
                {link || item.title}
           </DropdownMenuItem>
        
    })
    return ( 
        <div className="menu">
            {sectionMenu}
        </div>
    )

}

export default DropdownMenuSection
