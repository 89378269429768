import React, { Component } from 'react'
import classes from './GreetingPage.module.css'

// import custom components
import Popup from '../Popup/Popup'
import GreeterScreen from '../GreeterScreen/GreeterScreen'
import Authenticate from '../Forms/Authenticate/Authenticate'
import AccountRegistrationForm from '../Forms/AccountRegistration/AccountRegistrationForm'
import GreetingBackground from '../GreetingBackground/GreetingBackground'
/**
 * Shows the first page of the website
 *
 * @class GreetingPage
 * @extends {Component}
 */
class GreetingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: 'greeting',
            back: false
        }

        this.clickHandler = this.clickHandler.bind(this)
    }
    
    /**
     * Handles clicks on buttons and updates state.
     *
     * @param {object} event - The triggered event.
     * @memberof GreetingPage
     */
    clickHandler = (event) => {
        if (!event.target.id) {
            this.setState({show: 'greeting', back: false})

        } else {

            this.setState({show: event.target.id, back:true})
        }
    }

    /**
     * Renders the components
     */
    render () {
        let toRender = null

        switch (this.state.show) {
            case 'greeting':
                toRender = <GreeterScreen clicked={this.clickHandler}/>
                break
            case 'login':
                toRender = <Authenticate />
                break
            case 'register':
                toRender = <AccountRegistrationForm />
                break
            default:
                toRender = <GreeterScreen clicked={this.clickHandler}/>
                break
        }
    
        return (
            <div className={classes.background}>
                <GreetingBackground />
                <Popup action={this.clickHandler} actionButtonName={this.state.back ? 'Back' : null}> 
                    {toRender}
                </Popup>
            </div>
        )
    }
}

export default GreetingPage
