import React, { Component } from 'react'
import { Redirect } from "react-router-dom"

import fetchApi from '../../Helpers/fetchApi'

import styles from './AccountRegistrationForm.module.css'
import InputField from '../InputField/InputField'
import Authenticate from '../Authenticate/Authenticate'

export default class AccountRegistrationForm extends Component {
    state = {
        registrationForm: {
            username: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Username',
                    name: 'username'
                },
                value: '',
                validation: {
                    isValid: false,
                    required: true,
                    minLength: 3,
                    maxLength: 15,
                    safeLettersOnly: true
                },
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Email',
                    name: 'email'
                },
                value: '',
                validation: {
                    isValid: false,
                    required: true,
                    minLength: 5,
                    maxLength: 50,
                    email: true
                },
                touched: false
            },
            // country: {
            //     elementType: 'select',
            //     elementConfig: {
            //         options: [
            //             {value: 'sweden', displayValue: 'Sweden'},
            //             {value: 'finland', displayValue: 'Finland'},
            //             {value: 'denmark', displayValue: 'Denmark'}
            //         ]
            //     },
            //     validation: {},
            //     touched: false
            // },
            passphrase: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Passphrase',
                    name: 'passphrase'
                },
                value: '',
                validation: {
                    isValid: false,
                    required: true,
                    minLength: 10,
                    maxLength: 100
                },
                touched: false
            },
            passphraseCheck: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Confirm passphrase',
                    name: 'passphrasecheck'
                },
                value: '',
                validation: {
                    isValid: false,
                    required: true,
                    minLength: 10,
                    maxLength: 100,
                    mustMatch: true
                },
                touched: false
            },
        },
        isLoading: false,
        toRender: 'form',
        userExist: null,
        emailExist: null
    }

    checkValidity (value, rule) {
        let isValid = true

        if (rule.required) {
            isValid = value.trim() !== '' && isValid
        }

        if (rule.minLength) {
            isValid = value.length >= rule.minLength && isValid
        }

        if (rule.maxLength) {
            isValid = value.length <= rule.maxLength && isValid
        }

        if (rule.safeLettersOnly) {
            isValid = (/^[\w0-9\+S\(\)\.\-]+$/gi).test(value) && isValid
        }

        if (rule.email) {
            isValid = (/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/).test(value) && isValid
        }

        if (rule.mustMatch) {
            isValid = value === this.state.registrationForm.passphrase.value && isValid
        }


        return isValid
    }

    /**
     * Handles the change of inputfields.
     *
     * @memberof AccountRegistrationForm
     * @param {object} event - The event that triggered the function call.
     * @param {string} identifier - The id of the element.
     */
    inputChangedHandler = (event, identifier) => {
        const formCopy = {
            ...this.state.registrationForm
        }
        const elementCopy = {
            ...formCopy[identifier]
        }

        elementCopy.value = event.target.value
        elementCopy.validation.isValid = this.checkValidity(elementCopy.value, elementCopy.validation)
        elementCopy.touched = true
        formCopy[identifier] = elementCopy

        this.setState({registrationForm: formCopy})
    }


    /** 
     * Handes the creation of the account
     * 
     * @param {object} event - The event that triggered the functioncall
     */
    createAccountHandler = async (event) => {
        event.preventDefault()
        const newState = {
            ...this.state,
            isLoading: true,
            toRender: null,
            userExist: null,
            emailExists: null
        }
        this.setState(newState)

        const formData = {}
        for (const element in this.state.registrationForm) {
            formData[element] = this.state.registrationForm[element].value
        }

        try {
            const response = await fetchApi.post.createAccount(formData)

            // If account created successfully set state to render login
           if (response.status === 'success') {
               const newState = {
                   ...this.state,
                   isLoading: false,
                   userExist: null,
                   emailExists: null,
                   toRender: 'login'
                }
                this.setState(newState)
            } else {
            // If an error occured, select error message to render on screen
                if (response.error.message.includes('Username already exist')) {
                    this.setState({
                        ...this.state,
                        userExist: true,
                        isLoading: false,
                        emailExists: null,
                        toRender: 'form'
                    })
                } 
                
                if (response.error.message.includes('Email already exist')) {
                    this.setState({
                        ...this.state,
                        userExist: null,
                        isLoading: false,
                        emailExist: true,
                        toRender: 'form'
                    })
                }
            
            }
            
        } catch (error) {
            // Render account registration form if other problem arises
            const newState = {
                ...this.state,
                isLoading: false,
                toRender: 'form'
            }
            this.setState(newState)
        }
    }

    render () {
        let form = null
        let login = null
        // let error = null

        const formArray = []
        for (const key in this.state.registrationForm) {
            formArray.push({
                id: key,
                config: this.state.registrationForm[key]
            })
        }

        if (this.state.toRender === 'form') {
            form = (
                <form onSubmit={this.createAccountHandler} className={styles.formElement}>
                    <h2>Create your account</h2>
                    {formArray.map(formElement => {
                    return <InputField 
                        key={formElement.id}
                        elementType={formElement.config.elementType} 
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        isValid={formElement.config.validation.isValid}
                        touched={formElement.config.touched}
                        changed={(event) => { this.inputChangedHandler(event, formElement.id) }} />
                    })}
                    <input id="createaccount" type="submit" value="Create account" className={styles.submitBtn} />
                </form>
            )
        } else if (this.state.toRender === 'login') {
            form = null
            login = (
                <div className={styles.authenticateWrapper}>
                    <h2 className={styles.authenticateHeader}>Your account was created </h2>
                    <p className={styles.authenticateParagraph}>Please log in to get started</p>
                    <Authenticate />
                </div>
            )
        }

        return (
            <div>
                {this.state.isLoading ? <p>Loading...</p> : null}
                {this.state.emailExist ? <p>Email is already registered</p> : null}
                {this.state.userExist ? <p>Username is already taken</p> : null}
                {form}
                {login}

            </div>
        )
    }
}
