// dependencies imprt
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// component import
import Image from '../../../images/profile_pic1.svg'
// import Popup from '../../Popup/Popup'

// css imports
import styles from './ProfilePicture.module.css' 

/**
 * Renders the profile picture with the ability to change picture
 *
 * @class ProfilePicture
 * @extends {Component (props)} - Takes pros as an argyment and passes them to Component.
 */
class ProfilePicture extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props.user,
            showPopup: false
        }
        this.showPopupHandler.bind(this)
    }

    /**
     * Handles wether to show popup or not.
     *
     * @memberof ProfilePicture
     */
    showPopupHandler = (event) => {
        event.preventDefault()
        this.setState({showPopup: !this.state.showPopup }) 
    }

    /**
     * Renders content
     *
     * @returns {object} - JSX object representing the HTML for the DOM
     * @memberof ProfilePicture
     */
    render () {
        return (
            <div className={styles.profilePicture_wrapper}>
                <img src={Image}  className={styles.image} onClick={this.showPopupHandler} alt="profilepicture" />
                <Link to="/change-profile" className={styles.change_profile}>Change profile</Link>
                {/* {this.state.showPopup ? <Popup close={this.showPopupHandler} >A child</Popup> : null} */}
            </div>
        )
    }
}

export default ProfilePicture

