import React, { useState } from 'react'

// imprt styles
import styles from './FalldownInformation.module.css'

/** 
 * Renders renders information that will expand like a dropdown upon click.
 * Shows a header and when user clicks more information is shown.
 * 
 * @returns {JSX} - JSX that will be rendered to the DOM.
*/
const FallodwnInformation = (props) => {
    const { header, bread, descriptive } = props.content
    const [ showDetails, setShowDetails ] = useState(false)
    let detailsStyle = []
    let arrowStyle = []

    if (showDetails) {
        detailsStyle = [styles.content_wrapper, styles.show_details]
        arrowStyle = [styles.arrow_wrapper, styles.point_up]
        
    } else {
        detailsStyle = [styles.content_wrapper, styles.hide_details]
        arrowStyle = [styles.arrow_wrapper, styles.point_down]
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.header_bar} onClick={() => { setShowDetails(!showDetails) }}>
                <div className={arrowStyle.join(' ')}>
                    <div className={styles.left_line}></div>
                    <div className={styles.right_line}></div>
                </div>
                <div className={styles.header}>{header}</div>
            </div>
            <div className={detailsStyle.join(' ')}>
                <p className={styles.content_bread_text}>
                    {bread}
                </p>
                <p className={styles.content_descriptive_text}>
                    {descriptive}
                </p>
            </div>
        </div>
    )
}

export default FallodwnInformation
