import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as actions from '../../store/actions/index'
import InputField from '../InputField/InputField'

import styles from './Authenticate.module.css'

// make an export here aswell to avoid connection to redux store when testing
export class Authenticate extends Component {
    state = {
        authenticationForm: {
            username: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Username',
                    name: 'username'
                },
                value: '',
                validation: {
                    isValid: false,
                    required: true,
                    maxLength: 15,
                    safeLettersOnly: true
                },
                touched: false
            },
            passphrase: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Passphrase',
                    name: 'passphrase'
                },
                value: '',
                validation: {
                    isValid: false,
                    required: true,
                    maxLength: 100
                },
                touched: false
            }
        },
        isLoading: false
    }

    checkValidity (value, rule) {
        let isValid = true

        if (rule.required) {
            isValid = value.trim() !== '' && isValid
        }

        if (rule.minLength) {
            isValid = value.length >= rule.minLength && isValid
        }

        if (rule.maxLength) {
            isValid = value.length <= rule.maxLength && isValid
        }

        if (rule.safeLettersOnly) {
            isValid = (/^[\w0-9\+]+$/gi).test(value) && isValid
        }

        return isValid
    }

    inputChangedHandler = (event, identifier) => {
        const formCopy = {
            ...this.state.authenticationForm
        }
        const elementCopy = {
            ...formCopy[identifier]
        }

        elementCopy.value = event.target.value
        elementCopy.validation.isValid = this.checkValidity(elementCopy.value, elementCopy.validation)
        elementCopy.touched = true
        formCopy[identifier] = elementCopy

        this.setState({authenticationForm: formCopy})
    }

    signinHandler = async (event) => {
        event.preventDefault()
        this.props.onAuthenticate(this.state.authenticationForm.username.value, this.state.authenticationForm.passphrase.value)
    
    }

    render () {
        // convert the state object to an array for easier management.
        const formArray = []
        for (const key in this.state.authenticationForm) {
            formArray.push({
                id: key,
                config: this.state.authenticationForm[key]
            })
        }

        
        let form = (
            <form onSubmit={this.signinHandler} className={styles.formElement}>
                <h3>Sign in</h3>
                {formArray.map(formElement => {
                   return <InputField 
                    key={formElement.id}
                    id={formElement.id}
                    elementType={formElement.config.elementType} 
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    isValid={formElement.config.validation.isValid}
                    touched={formElement.config.touched}
                    changed={(event) => { this.inputChangedHandler(event, formElement.id) }} />
                })}
                <input type="submit" value="Sign in" className={styles.submitBtn}/>
            </form>
        )
        
        // if user enters wrong credentials, error message is shown on screen.
        let errorMessage = null
        if (this.props.error?.message === 'invalid credentials') {
            errorMessage = (
            <p className={styles.errorMessage}>Username and passphrase did not match. <br />
                Please try again!</p>
            )
        }

        // if user is authenticated, redirect to collections page.
        let authRedirect = null
        if (this.props.isAuthenticated) {
            authRedirect = <Redirect to="/collections" />
        }

        return (
            <div className={styles.wrapper}>
                {authRedirect}
                {errorMessage}
                {form}
            </div>
            )
    }
}
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        username: state.authentication.username,
        token: state.authentication.token,
        error: state.authentication.error,
        loading: state.authentication.loading
    }

}

const mapDispatchToProps = dispatch => {
    return {
        onAuthenticate: (username, password) =>  dispatch(actions.auth(username, password)) 
    }
}

// null = mapStateToProps function
export default connect(mapStateToProps, mapDispatchToProps)(Authenticate)
