import React from 'react'

import styles from './InputField.module.css'

const InputField = props => {
    let elementType = null
    let classList = ''
    let validationError = null
    
    if(!props.isValid && props.touched) {
        classList = styles.invalid
        switch (props.elementConfig.placeholder) {
            case 'Confirm passphrase':
                validationError = <p className={styles.validationError}>Passphrases must match</p>
                break;
            case 'Passphrase':
                validationError = <p className={styles.validationError}>Passphrase must be longer than 10 characters</p>
                break;
            case 'Email':
                validationError = <p className={styles.validationError}>A valid email format is example@email.com or example@e.mail.com</p>
                break;
            case 'Username':
                validationError = <p className={styles.validationError}>A valid username can contain letters, numbers, dots (.), dashes (-) or underscores (_) and must be between 3 - 16 characters</p>
                break;
        
            default:
                validationError = <p className={styles.validationError}>Please enter a valid value!</p>
                break;
        }
    }
    
    if(props.isValid && props.touched) {
        classList = styles.valid
        validationError = null
    }

    switch (props.elementType) {
        case 'input':
            elementType = <input type={props.elementType} {... props.elementConfig} value={props.value} onChange={props.changed} className={[styles.inputfield, classList].join(' ')} />
            break
        case 'textarea':
            elementType = <textarea value={props.value} onChange={props.changed} className={[classList, styles.textareafield].join(' ')} />
            break        
        case 'select':
            elementType = (
            <select onChange={props.changed} >
                {props.elementConfig.options.map(option => {
                    return (<option key={option.value} value={option.value}>{option.displayValue}</option>)
                })}
            </select>
                )
            break        
        default:
            elementType = <input type={props.elementType} value={props.value} {... props.elementConfig} onChange={props.changed} className={[classList, styles.inputfield].join(' ')} />
            break
    }

    return (
        <div>
            {elementType}
            {validationError}
        </div>
    )
}

export default InputField
