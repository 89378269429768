import React from 'react'

// import custom components
import FalldownInformation from '../../FalldownInformation/FalldownInformation'

// imprt styles
import styles from './StandardGuide.module.css'

/** 
 * Renders renders a standard guide. Takes two properties: "content", witch is an object containing a header, preamble and a body.
 * Or you can provide a FalldownInformation-block by providing the property "falldownContent". This prop will send the information to a FalldownInformation-component.
 * 
 * @returns {JSX} - JSX that will be rendered to the DOM.
*/
const StandardGuide = (props) => {
    const { header, preamble, body } = props.content

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.header}>
                {header}
            </h1>
            <p className={styles.content_preamble}>
                {preamble}
            </p>
            <div className={styles.content_body}>
                {props.falldownContent ? <FalldownInformation content={props.falldownContent}/> : body}
            </div>
        </div>
    )
}

export default StandardGuide
