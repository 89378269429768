import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './Header.module.css'
import profilePic from '../images/profile_pic1.svg'
import logo from '../images/shopr_logo.svg'

import Popup from '../Popup/Popup'
import FallingDropdownMenu from '../menus/DropdownMenu/FallingDropdownMenu/FallingDroppdownMenu'

class Header extends Component {
    constructor (props) {
        super(props)

        this.state = {
            showPopup: false
        }

        this.showPopupHandler = this.showPopupHandler.bind(this)
    }

    showPopupHandler = (event) => {
            event.preventDefault()
            this.setState({showPopup: !this.state.showPopup })
        }

    render () {
        let toRender = null

        if (this.props.isAuthenticated) {
            toRender = (
                <ul>
                    {/* <div className={styles.line}></div> */}
                    <li><NavLink exact to="/" activeClassName="my-active">Collections</NavLink></li>
                    {/* <li><NavLink exact to="/collections" className={styles.hide_at_600px} >My collections</NavLink></li> */}
                    <div className={styles.line}></div>
                    <FallingDropdownMenu icon={profilePic}>
                    </FallingDropdownMenu>
                </ul>
            )
        } else {
            toRender = (
                <ul>
                    <div className={styles.line}></div>
                    <li><NavLink to="/signin" className={styles.profilePic}><img src={profilePic} alt="Log in icon representing a person" /></NavLink></li>
                </ul>
            )
        }

        return (
                <header className={styles.header}>
                    <div className={styles.logo_wrapper}>
                        <img src={logo} alt="shopr logotype" />
                    </div>
                    <div className={styles.nav_wrapper}>
                        {toRender}
                    </div>
                    {this.state.showPopup ? <Popup close={this.showPopupHandler} /> : null}
                </header>
                )
    }
}

export default Header
