
/**
 * Makes a get request to backend API for all the users collections and converts from JSON to js-object.
 * 
 *  @returns {object} - A javascript array of collection objects.
 */
  const collections = async () => {
      try {
        const token = window.localStorage.getItem('JWT')
        const response = await fetch('/api/collections', {
          method: 'GET',
          headers: { authorization: `Bearer ${token}`}
        })
        const collections = await response.json()
        
        if (response.status !== 200) {
          throw Error(collections.message) 
        }

        return collections
      } catch (error) {
        console.error('error occured')
      } 
    }

/**
 * Makes a get request to backend API for all the collections items and converts from JSON to js-object.
 * 
 * @param {string} - Id of the collection.
 *  @returns {object} - A javascript array of item objects.
 */
const collection = async (id) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/collections/' + id + '/items', {
      method: 'GET',
      headers: { authorization: `Bearer ${token}`}
    })
    const items = await response.json()
    
    if (response.status !== 200) {
      throw Error(items.message) 
    }

    return items
  } catch (error) {
    console.error('error occured')
  } 
}

/**
 * Fetches the api and returns the number of items the user has.
 * 
 * @returns {number} - The number of items the user has.
 */
const numberOfItems = async () => {
  try {
    const token = window.localStorage.getItem('JWT')
    let response = await fetch('/api/collections/get-number-of-items', {
      method: 'GET',
      headers: { authorization: `Bearer ${token}`}
    })
    response = await response.json()

    if (response.status !== 'success') {
      throw Error(response.message) 
    }

    return response.data.numberOfItems.length || 0
  } catch (error) {
    console.error('error occured')
  } 
}

/**
 * Sends a create collection request to the api
 * 
 * @param {object} formData - collection information.
 * @returns {object} - Response from the server.
 */
const createCollection = async (formData) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/collections/create', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(formData)
    })
    return response.json()
    
  } catch (error) {
    console.error('error from create collection')  
  }
}
/**
 * Sends a create item request to the api
 * 
 * @param {object} formData - Item information.
 * @returns {object} - Response from the server.
 */
const createItem = async (formData) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/collections/save-item', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(formData)
    })
    return response.json()
    
  } catch (error) {
    console.error('error from create item')  
  }
}

/**
 * Sends a delete-collection request to the api.
 * 
 * @param {object} id - Id of the collection to delete.
 * @returns {object} - Response from the server.
 */
const deleteCollection = async (id) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/collections/delete/collection', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify({id: id})
    })
  
    return response.json()
    
  } catch (error) {
    console.error('error from delete collection')  
  }
}

/**
 * Sends a edit-collection request to the api.
 * 
 * @param {object} formData - The data to be passed to the api: title, id and description.
 * @returns {object} - Response from the server.
 */
const editCollection = async (formData) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/collections/edit/collection', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(formData)
    })
  
    return response.json()
    
  } catch (error) {
    console.error('error occured when editing collection')  
  }
}

/**
 * Sends a edit-item request to the api.
 * 
 * @param {object} formData - The data to be passed to the api: title, id and description.
 * @returns {object} - Response from the server.
 */
const editItem = async (formData) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/collections/edit/item', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(formData)
    })
  
    return response.json()
    
  } catch (error) {
    console.error('error occured when editing item')  
  }
}

/**
 * Sends a delete-itemn request to the api.
 * 
 * @param {object} id - Id of the item to delete.
 * @returns {object} - Response from the server.
 */
const deleteItem = async (id) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/collections/delete/item', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify({id: id})
    })
  
    return response.json()
    
  } catch (error) {
    console.error('error from delete item')  
  }
}

/**
 * Sends a create account request to the api
 * 
 * @param {object} formData - Registration information.
 * @returns {string} - Response from the server.
 */
const createAccount = async (formData) => {
  try {
    const response = await fetch('/api/user/create-user', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
  
    return response.json()
    
  } catch (error) {
    return error
  }
}

/**
 *  Handles the request for deleting the account.
 * 
 * @returns {object} - Response object.
 */
const deleteAccount = async (passphrase) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/user/delete-user', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify({passphrase: passphrase })
    })
  
    return response.json()
    
  } catch (error) {
    return error
  }
}

/**
 * Sends a change account details request to API
 * 
 * @param {object} formData - New account details.
 * @returns {string} - Response from the server.
 */
const changeAccountDetails = async (formData) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/user/change-user-details', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(formData)
    })
    
    const json = await response.json()
    return json
    
  } catch (error) {
    console.error('Could not change account')  
  }
}

/**
 * Sends a sign in request to the api
 * 
 * @param {object} formData - User credentials.
 * @returns {string} - Access token.
 */
const authenticate = async (formData) => {
  try {
    const responseData = await fetch('/api/user/signin', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
  
    return responseData.json()
    
  } catch (error) {
    console.error('An error occured when checking authentication')  
  }
}
/**
 * Sends a log out request to api.
 * 
 * @param {object} formData - User credentials.
 * @returns {string} - Access token.
 */
const logOut = async (formData) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const responseData = await fetch('/api/user/logout', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`
      }
    })

    window.localStorage.removeItem('JWT')
    return responseData.json()
    
  } catch (error) {
    console.error('Error from authenticate')  
  }
}

/**
 * Checks if token is valid
 * 
 * @param {object} token - The token to check.
 * @returns {string} - Access token.
 */
const checkToken = async (token) => {
  try {
    const responseData = await fetch('/api/user/check-token', {
      method: 'POST',
      headers: {
        'content-Type': 'application/json',
        authorization: `Bearer ${token}`
      }
    })
  
    return responseData.json()
    
  } catch (error) {
    console.error('error')  
  }
}

/**
 * Sends a url to the api for parsing og tags.
 * 
 * @param {string} url - Url to parse. 
 */
const parseSite = async (url) => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/site', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify({url: url})
    })
  
    return response.json()

  } catch (error) {
    console.error('Error')
  }
}

const recievedExtensionInfo = async () => {
  try {
    const token = window.localStorage.getItem('JWT')
    const response = await fetch('/api/user/recieved-extension-info', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify({infoRecieved: true})
    })
  
    return response.json()

  } catch (error) {
    console.error('Error')
  }
}

const fetchApi = {
  get: {
    collections: collections,
    collection: collection,
    numberOfItems: numberOfItems
  },
  post: {
    createAccount: createAccount,
    createItem: createItem,
    deleteAccount: deleteAccount,
    editCollection: editCollection,
    editItem: editItem,
    authenticate: authenticate,
    logOut: logOut,
    checkToken: checkToken,
    changeAccountDetails: changeAccountDetails,
    createCollection: createCollection,
    deleteCollection: deleteCollection,
    deleteItem: deleteItem,
    parseSite: parseSite,
    recievedExtensionInfo: recievedExtensionInfo
  }
}

/** 
 * Expose fetchApi
*/
export default fetchApi 