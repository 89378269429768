import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

// import helpers
import fetchApi from '../../../Helpers/fetchApi'

// import styles
import styles from './AddItem.module.css'

/**
 * Adds an new item to the specified collection.
 *
 * @class AddItem
 * @extends {Component}
 */
class AddItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: null,
            preview: null,
            redirect: false,
            toShow: 'paste url',
            isLoading: false,
            error: false
        }

        // bind functions
        this.onSubmitToParseHandler = this.onSubmitToParseHandler.bind(this)
        this.submitItemHandler = this.submitItemHandler.bind(this)
        this.onParseInputChangeHandler = this.onParseInputChangeHandler.bind(this)
        this.onPreviewInputChangeHandler = this.onPreviewInputChangeHandler.bind(this)
    }

    // componentWillUnmount = () => {
    //     console.log('cleanup is neccecary')
    // }

    /**
     * fetches the server with the url to parse for product information.
     *
     * @memberof AddItem
     * @param {object} event - The event that triggered the functioncall.
     */
    onSubmitToParseHandler = async (event) => {
        
        try {
            event.preventDefault()
            this.setState({
                ...this.state,
                isLoading: true
            })
            let responseData = await fetchApi.post.parseSite(this.state.inputValue)
           
            if (responseData.status === 'success') {
                const newState = {
                    ...this.state,
                    preview: {
                        title: responseData.data.title,
                        description: responseData.data.description,
                        imageURL: responseData.data.imageURL,
                        url: responseData.data.url,
                        collectionId: this.props.collectionId
                    },
                    toShow: 'preview item',
                    isLoading: false
                }
    
                this.setState(newState)
            } else {
                const newState = {
                    ...this.state,
                    preview: {
                        title: null,
                        description: null,
                        imageURL: null,
                        url: null,
                        collectionId: null
                    },
                    toShow: 'preview item',
                    isLoading: false,
                    error: true
                }
    
                this.setState(newState)
            }
        } catch (error) {
            console.log('an error occured')
            const newState = {
                ...this.state,
                preview: {
                    title: null,
                    description: null,
                    imageURL: null,
                    url: null,
                    collectionId: null
                },
                toShow: 'preview item',
                isLoading: false,
                error: true
            }

            this.setState(newState)
        }
    } 

    /**
     * Handles the submission of the item to the database.
     *
     * @memberof AddItem
     * @param {object} e - The event that triggered the functioncall.
     */
    submitItemHandler = async (e) => {
        e.preventDefault()
        this.setState({
            ...this.state,
            isLoading: true
        })

        try {
            const resp = await fetchApi.post.createItem(this.state.preview)

            if (resp.error) {
                throw new Error('error')
            }

            this.setState({
                ...this.state,
                isLoading: false,
                error: false,
                toShow: 'success'
            })
        } catch (error) {
            this.setState({
                ...this.state,
                isLoading: false,
                toShow: 'preview item',
                error: true
            })
        }
    }

    /**
     * Handles the change of the inputfield and updates state with the input value.
     *
     * @memberof AddItem
     * @param {object} event - The event that triggered the functioncall.
     */
    onParseInputChangeHandler = (event) => {
        this.setState({inputValue: event.target.value})
    }

    /**
     * Handles the change event of fields in the preview of the item.
     *
     * @memberof AddItem
     * @param {object} event - The event that triggered the functioncall.
     */
    onPreviewInputChangeHandler = (event) => {
        const previewCopy = {...this.state.preview}

        for (const el in previewCopy) {
            if (el === event.target.name) {
                previewCopy[el] = event.target.value || ' '
            }
        }

        const stateCopy = {
            ...this.state, 
            preview: previewCopy
        }

        this.setState(stateCopy)
    }

    render () {
        const errorMessage = <p className={styles.error_message}>An error occured when trying to save your product <br /> Please make another try. If this error keeps happening, Please contact us at shopr.contact@gmail.com</p> 
        
        let toRender = null
        if (this.state.toShow === 'preview item') {
            const title = this.state.preview.title !== 'not found' ? this.state.preview.title : 'We couldn\'t find a title, feel free to add your own'
            const description = this.state.preview.description !== 'not found' ? this.state.preview.description : 'We couldn\'t find a description of the product, feel free to add your own'

            toRender = (
                <div className={styles.preview_wrapper}>
                    <div className={styles.preview_header_wrapper}>
                        <h2>This is how your item will be saved</h2>
                        {this.state.error ? errorMessage : null}
                    </div>

                    <div className={styles.preview_content}>
                        <form onSubmit={this.submitItemHandler} className={styles.formElement}>
                            <input type="text" name="title" value={title} onChange={this.onPreviewInputChangeHandler} className={styles.preview_input}/>
                            <textarea name="description" value={description} onChange={this.onPreviewInputChangeHandler} className={styles.preview_textarea} ></textarea>
                            <input type="submit" value="Save" className={styles.sendBtn} />
                        </form>
                        <div className={styles.preview_image_wrapper}>
                            {this.state.preview.imageURL !== 'not found' ? <img src={this.state.preview.imageURL} className={styles.image} /> : <p className={styles.no_image}>We could not find an image of the product</p>}
                        </div>
                    </div>
                </div>
            )
        } else if (this.state.toShow === 'success') {
            toRender = (
                <div className={styles.successWrapper}>
                    <h2 className={styles.success_header}>Product saved successfully!</h2>
                    <p className={styles.success_message}>A new product was added to your collection</p>
                    <a href={'/collections/' + this.props.collectionId + '/view-collection'} className={styles.sendBtn}>Close</a>
                </div>
            )
        } else {
            toRender = (
                <div className={styles.wrapper}>
                    <h2 className={styles.header}>Add a product</h2>
                    <p className={styles.sidenote}>Copy the url from the adressbar to the product you would like to add and place 
                    the url in the inputfield below</p>
                    
                    <form onSubmit={this.onSubmitToParseHandler} className={styles.formElement}>
                        <input type="text" name="url" placeholder="ex https://www.awesomestore.com/awesomeproduct" onChange={this.onParseInputChangeHandler} className={styles.inputfield} />
                        <input type="submit" value="Add product" className={styles.sendBtn} />
                    </form> 
            </div>
            )
        }

        if (this.state.isLoading) {
            toRender = <p>Loading ... <br />
                This can take some time
            </p>
        }



        return (<React.Fragment>
            {this.state.redirect ? <Redirect to={'/collections/' + this.props.collectionId + '/view-collection'} /> : toRender }  
        </React.Fragment>)
    }
}

export default AddItem
