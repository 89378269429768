// import dependencies
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

// import custom components
import Popup from '../../../Popup/Popup'

// import actions
import * as actions from '../../../store/actions/index'

// import custom helpers
import fetchApi from '../../../Helpers/fetchApi'

// css imports
import styles from './DeleteAccount.module.css'

/**
 * Renders delete account component.
 *
 * @export DeleteAccount
 * @class DeleteAccount.
 * @extends {Component}
 */
export class DeleteAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputData: null,
            showPopup: false,
            redirect: false,
            match: true,
            isLoading: false,
            wrongPassphrase: false
        }

        // bind methods
        this.showPopup = this.showPopup.bind(this)
    }

    inpotChangedHandler = (e) => {

    }

    /**
     * Handles the submission of the two passphrase fields.
     *
     * @param {object} e - The event that triggered the function call.
     * @memberof DeleteAccount
     */
    onSubmitHandler = async (e) => {
        e.preventDefault()
        
        const formElements = e.target.children
        let passphrase = 'xx'
        let passphraseCheck = 'x'
        
        for (let i = 0; i < formElements.length; i++) {
            switch (formElements[i].name) {
                case 'passphrase':
                    passphrase = formElements[i].value
                    break;
                case 'passphrasecheck':
                    passphraseCheck = formElements[i].value
                    break;
            
                default:
                    break;
            }

        }

        if (passphrase !== passphraseCheck) {
            const newState = {
                ...this.state,
                match: false,
                wrongPassphrase: false,
                redirect: false
            }
            this.setState(newState)
        } else {
            const newState = {
                ...this.state,
                inputData: passphrase,
                showPopup: true,
                match: true,
                isLoading: false,
                redirect: false
            }
            this.setState(newState)
        }

    }

    /**
     * Handles the display of the popup.
     *
     * @memberof DeleteAccount
     */
    showPopup = () => {
        const newState = {
            ...this.state,
            showPopup: !this.state.showPopup
        }

        this.setState(newState)
    }

    /**
     * Handles the delete request of the account.
     *
     * @memberof DeleteAccount
     */
    deleteAccountHandler = async () => {
        const resp = await fetchApi.post.deleteAccount(this.state.inputData)
     
        // if (resp.error && resp.error?.message === 'wrong passphrase' || resp.error?.message === 'invalid token') {
        if (resp.error?.message === 'wrong passphrase' || resp.error?.message === 'invalid token') {
            this.setState({
                ...this.state,
                wrongPassphrase: true,
                showPopup: false,
                match: true,
                redirect: false
            })
        } else {
            this.setState({
                ...this.state,
                redirect: true
            })

            this.props.onLogout()
        }
    }

    /**
     * Renders the component.
     *
     * @returns {JSX} - The JSX to render to the DOM.
     * @memberof DeleteAccount
     */
    render () {
        const { showDeleteForm } = this.props
        let showDeleteAccount = null
        let popup = null 

        if (showDeleteForm) {
            showDeleteAccount = (
                <div className={styles.form_wrapper}>
                    <form onSubmit={this.onSubmitHandler}>
                        <label className={styles.label} >Enter your passphrase to delete you account</label>
                        {this.state.match ? null : <p>Passphrases must match</p>}
                        {this.state.wrongPassphrase ? <p>The passphrase was incorrect, Please try again!</p> : null }
                        <input type="password" name="passphrase" placeholder="Enter passphrase" className={styles.inputfiled} required/>
                        <input type="password" name="passphrasecheck" placeholder="Confirm passphrase" className={styles.inputfiled} required/>
                        <input type="submit" name="delete" value="Delete account" className={styles.submit_btn}/>
                    </form>
                </div>
            )
        }

        if (this.state.showPopup) {
            popup = (
                <Popup action={() => { this.showPopup() }} actionButtonName="Close">
                    <div className={styles.popup_wrapper}>
                        <div className={styles.header_wrapper}>
                            <h2 className={styles.popup_header}>Delete account</h2>
                            <p className={styles.popup_warning}>You are about to delete your account</p>
                            <p className={styles.popup_confirm}>Are you sure?</p>
                        </div>
                        <div className={styles.buttons_wrapper}>
                            <button onClick={this.deleteAccountHandler} className={styles.submit_btn}>Delete</button>
                            <button onClick={this.showPopup} className={styles.submit_btn} >Cancel</button>
                        </div>    
                    </div>    
                </Popup>
            )
        }

        return (
            <div className={styles.wrapper}>
                {this.state.redirect ? <Redirect to="/" /> : null}
                {this.state.showPopup ? popup : null}
                <h2 className={styles.header}>Delete account</h2>
                <p className={styles.info_text}>If you choose to delete your account all information about your account,
                all your collections and saved products will be removed and 
                we will not be able to restore this in any way.  </p>
                {showDeleteAccount}
            </div>
        )
    }
}

/**
 * Makes it possible for the component to dispatch events to redux.
 * 
 * @param {function} dispatch - Dispatches an event when called.
 */
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () =>  dispatch(actions.logout()) 
    }
}
export default connect(null, mapDispatchToProps)(DeleteAccount)

