import React, { Component } from 'react'
import fetchApi from '../../../Helpers/fetchApi'

// images inport
import avatar from '../../../images/profile_pic1.svg'

// css imports
import styles from './ChangeCivilStatus.module.css'

/**
 * Handles the change of civil status information
 * @param {object} props 
 */
export default class ChangeCivilStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props,
            saveBtnValue: null
        }
    }

    onChangeHandler = (e) => {
        const newState = {
            ...this.state
        }
        
        newState.civilStatusInfo[e.target.name] = e.target.value

        this.setState(newState)
    }

    onSubmitHandler = async e => {
        e.preventDefault()
        const response = await fetchApi.post.changeAccountDetails(this.state.civilStatusInfo)

        if (response.status ==='success') {
            this.setState({
                ...this.state,
                saveBtnValue: 'success'
            })
        }
        
        if (response.status === 'error') {
            this.setState({
                ...this.state,
                saveBtnValue: 'error'
            })

        }
    }

    render () {
        const { showForm, civilStatusInfo } = this.props
        let showChangeCivilStatusForm = null
        let saveBtnValue = null
        const btnStyles = [styles.submit_btn]

        switch (this.state.saveBtnValue) {
            case 'success':
                saveBtnValue = 'Saved!'
                btnStyles.push(styles.savedSuccess)
                break
                case 'error':
                    saveBtnValue = 'Couldn\'t save changes'
                    btnStyles.push(styles.savedError)
                break
            default:
                saveBtnValue = "Save changes"
        }

        // Change form
            showChangeCivilStatusForm = (
                <div className={styles.form_wrapper}>
                    <form onChange={this.onChangeHandler} onSubmit={this.onSubmitHandler}>
                        <label className={styles.labelHeader} >Account details</label>
                        <label className={styles.label} >First name</label>
                        <input type="text" name="firstName" value={this.state.civilStatusInfo.firstName} className={styles.inputfield}/>
                        <label className={styles.label} >Last name</label>
                        <input type="text" name="lastName" value={this.state.civilStatusInfo.lastName} className={styles.inputfield}/>
                        <label className={styles.label} >Workplace</label>
                        <input type="text" name="workplace" value={this.state.civilStatusInfo.workplace} className={styles.inputfield}/>
                        <label className={styles.label} >Location</label>
                        <input type="text" name="location" value={this.state.civilStatusInfo.location} className={styles.inputfield}/>
                        <input type="submit" name="saveCanges" value={saveBtnValue} className={btnStyles.join(' ')}/>
                    </form>
                </div>
            )


        return (
            <div className={styles.wrapper}>
                <div><h3 className={styles.header}>Change account details</h3></div>
                {showForm ? showChangeCivilStatusForm : null}
            </div>
        )
    }
}


