// dependencies imprt
import React, { Component } from 'react'

// component import
import Collections from '../../../../CollectionManager/Collections/Collections/Collections'

// css imports
import styles from './ProfileShowreel.module.css' 

/**
 * Shows a showreel of different categories.
 *
 * @class ProfileShowreel
 * @extends {Component (props)} - Takes pros as an argument and passes them to Component.
 */
class ProfileShowreel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: 'Collections'
        }

        this.displayShowreelHandler = this.displayShowreelHandler.bind(this)
    }

    /**
     * Handles witch view the showreel will show.
     *
     * @memberof ProfileShowreel
     */
    displayShowreelHandler = (event) => {
        this.setState({show: event.target.textContent})
    }
    
    /**
     * Renders content
     *
     * @returns {object} - JSX object representing the HTML for the DOM
     * @memberof ProfileShowreel
     */
    render () {
        let toRender = null

        switch (this.state.show) {
            case 'Collections':
                toRender = <Collections />
                break
            case 'Top stores':
                toRender = <h2>Top stores</h2>
                break
            case 'Collection snapshots':
                toRender = <h2>Snapshots</h2>
                break
            default:
                toRender = <Collections />
                break
        }

        return (
            <div className={styles.profileShowreel_wrapper}>
                <div className={styles.header} onClick={this.displayShowreelHandler}>
                    <span className={this.state.show === 'Collections' ? styles.active : null} >Collections</span>
                    <span className={this.state.show === 'Top stores' ? styles.active : null}>Top stores</span>
                    <span className={this.state.show === 'Collection snapshots' ? styles.active : null}>Collection snapshots</span>
                </div>
                <div className={styles.showreel_content}>
                    {toRender}
                </div>
            </div>
        )
    }

}

export default ProfileShowreel

