import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './css/App.css';

import * as actions from './store/actions/index'

import Header from './Header/Header'
import CenterContent from './CenterContent/CenterContent'
import GreetingPage from './GreetingPage/GreetingPage'



class App extends Component {
  constructor (props) {
    super(props)
    // this.state = {
    //     isLoading: true
    //   }
    this.props.onCheckAuthentication()
  }
  
  // componentDidMount = async () => {
  // }
  

  render() {
    let toRender = null
    if (this.props.user.isAuthenticated) { 
     toRender = <CenterContent isAuthenticated={this.props.user.isAuthenticated} userDetails={this.props.user} />
    } else {
      toRender =  <GreetingPage />
    }
    
    return (
      <BrowserRouter>
          <Header isAuthenticated={this.props.user.isAuthenticated} />
        <div className="main-wrapper">
          {toRender}
        </div>
      </BrowserRouter>  
    )
  }
}

/**
 * Makes the properties in redux store avaliable in component
 * 
 * @param {object} state - Current redux state
 */
const mapStateToProps = (state) => {
  return {
    user: {
      isAuthenticated: state.user.isAuthenticated,
      username: state.user.username,
      token: state.user.token,
      error: state.user.error,
      loading: state.user.loading,
      recievedExtensionInfo: state.user.recievedExtensionInfo
    }
  }

}

/**
 * Makes it possible for the component to dispatch events to redux.
 * 
 * @param {function} dispatch - Dispatches an event when called.
 */
const mapDispatchToProps = dispatch => {
  return {
      // onAuthenticate: (username, password) =>  dispatch(actions.auth(username, password))
      onCheckAuthentication: () => dispatch(actions.checkAuthentication())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
