// dependencies import
import React, { Component } from 'react'
import { connect } from 'react-redux'

// component import
import ProfileCivilStatus from '../ProfileCivilStatus/ProfileCivilStatus'
import ProfileAccountStatus from '../ProfileAccountStatus/ProfileAccountStatus'
import ProfilePicture from '../ProfilePicture/ProfilePicture'
import ProfileShowreel from '../ProfileShowreel/ProfileShowreel/ProfileShowreel'

// css imports
import styles from './ViewProfile.module.css' 
import fetchApi from '../../../Helpers/fetchApi'

/**
 * Holds all logic for rendering profile page.
 *
 * @class Profile
 * @extends {Component (props)} - Takes pros as an argyment and passes them to Component.
 */
export class ViewProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {
                username: '',
                workplace: '',
                location: '',
                interests: [],
                collections: 0,
                items: 0,
                snapshots: 0,
                topStore: '',
                storeToplist: []
            }
        }
    }

    componentDidMount = async () => {
        const { username, workplace, location, interests } = this.props.user

        const respData = await fetchApi.get.collections()
        // data2 is temporary due to changes in response object structure. 
        // Check in other components if they safley can go over to data2.
        // If they can then remove data and rename data2 to data from response object on backend
        const numberOfItems = await fetchApi.get.numberOfItems()
        
        const newUser = { 
            username: username || '',
            workplace: workplace || '',
            location: location || '',
            interests: interests || [],
            collections: respData.data2.collections.length || 0,
            items: numberOfItems || 0,
            snapshots: 0,
            topStore: '',
            storeToplist: []
        }

        this.setState({user: newUser })
    }

    render () {
        return (
            <div className={styles.profile_wrapper}>
                <div className={styles.profile_mainContent}>
                    <div className={styles.profile_status} >
                        <ProfileCivilStatus user={this.state.user}/>
                        <ProfileAccountStatus user={this.state.user}/>
                    </div>
                    <div className="profile_picture">
                        <ProfilePicture user={this.state.user}/>
                    </div>
                </div>
                <div className="profile-showReel">
                    {/* <ProfileShowreel /> */}
                </div>
    
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
      user: {
        isAuthenticated: state.user.isAuthenticated,
        username: state.user.username,
        token: state.user.token,
        firstName: state.authentication.firstName,
        lastName: state.user.lastName,
        workplace: state.user.workplace,
        location: state.user.location,
        interests: state.user.interests,
        avatar: state.user.avatar,
        recievedExtensionInfo: state.user.recievedExtensionInfo,
        error: state.user.error,
        loading: state.user.loading
      }
    }
  }

export default connect(mapStateToProps, null)(ViewProfile)

