import React, { Component }  from 'react'

import styles from './CollectionsView.module.css'
import Collections from '../Collections/Collections'
import DisplayNavigation from '../../DisplayNavigation/DisplayNavigation'

// local components
import Sidebar from '../../../menus/Sidebar/Sidebar'
import Popup from '../../../Popup/Popup'
import CreateCollection from '../CreateCollection/CreateCollection'

/**
 * Renders the collection page with collections and sidebar.
 *
 * @param {object} props - The properties for the component.
 * @returns - JSX component.
 */
class CollectionsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showPopup: false
        }
        this.handlePopup = this.handlePopup.bind(this)
    }

    /** 
     * Handler for showing or removing popup.
    */
    handlePopup = () => {
        const newState = {
            ...this.state,
            showPopup: !this.state.showPopup
        }
        this.setState(newState)
    }

    render () {    
        // Add this when functionality is implemented
        const sidebarLinks = [
            // { url: '/collections', title: 'Organize'},
            // { url: '/collections', title: 'Edit'},
            // { url: '/collections', title: 'Remove'},
        ]
    
        let popup = null
        // check if popup will be rendered.
        if (this.state.showPopup) {
            popup = <Popup action={this.handlePopup} actionButtonName="Close" >
                        <CreateCollection closeOnSuccess={this.handlePopup}/>
                    </Popup>
        } else {
            popup = null
        }
        
        return (
            <div className={styles.wrapper}>
                {popup}
                <Sidebar items={sidebarLinks} action={this.handlePopup} newItem={{title: 'New collection'}} />
                <div>
                    <DisplayNavigation />
                    <Collections onDelete={(id) => {this.handleDeletePopup(id)}} />
                </div>
            </div>
        )
    }
}

/**
 * Expose component
*/
export default CollectionsView
