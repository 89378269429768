import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as actions from '../store/actions/index'

/**
 * Handles logout of the user.
 *
 * @class Logout
 * @extends {Component}
 */
class Logout extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    /**
     * Called when component renders. Will dispatc a logout event to update redux store
     *
     * @memberof Logout
     */
    componentDidMount () {
        this.props.onLogout()
    }

    /** 
     * renders a redirect to index when logged out
    */
    render () {
        return <Redirect to="/" />
    }
}

/**
 * Maps actions that the component will use to its props.
 * 
 * @param {function} dispatch - Function that will dispatch the given event to reducer.
 */
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () =>  dispatch(actions.logout()) 
    }
}

/** 
 * Expose component
*/
export default connect(null, mapDispatchToProps)(Logout)