import React from 'react'

// import images
import logo from '../images/shopr_logo.svg'

// import css
import styles from './GreeterScreen.module.css'

/** 
 * Shows the greeting screen
 * 
 * @param {object} props - The props for the component.
 * @returns {JSX} - JSX to render to the DOM.
*/
const GreeterScreen = (props) => {
    const { clicked } = props

    return (
        <div className={styles.wrapper}>
            <div className={styles.descriptionWrapper}>
                <div className={styles.logo_wrapper}>
                    <img src={logo} alt="shopr logotype" />
                </div>
                <h2 className={styles.header}>Makes online shopping fun</h2>
                <p className={styles.content}>With Shop'r online shopping gets easy. It has never been more convenient to
                buy online. Compare products, make collections and share them with your friends. </p>
            </div>
            <div className={styles.buttonsWrapper}>
                <button id="register" className={styles.registerBtn} onClick={(event) => { clicked(event)}}>Join SHOPR</button>
                <button id="login" className={styles.loginBtn} onClick={(event) => { clicked(event)}} >Sign in</button>
            </div>
        </div>
    )
}

export default GreeterScreen
