// import dependencies
import React, { Component } from 'react'
import { connect } from 'react-redux'

// import custom components
import ChangeProfileSection from '../ChangeProfileSection/ChangeProfileSection'
import ChangeCivilStatus from '../ChangeCivilStatus/ChangeCivilStatus'
import DeleteAccount from '../DeleteAccount/DeleteAccount'
// css imports
import styles from './ChangeProfile.module.css'

export class ChangeProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDeleteForm: false,
            showChangeCivilStatusForm: false,
            user: {
                username: '',
                workplace: '',
                location: '',
                interests: [],
                collections: 0,
                items: 0,
                snapshots: 0,
                topStore: '',
                storeToplist: []
            }
        }

        // Bind "this"
        this.setShowDeleteForm = this.setShowDeleteForm.bind(this)
        this.setShowChangeCivilStatusForm = this.setShowChangeCivilStatusForm.bind(this)
    }

    /**
     * Sets the state for showing the deleteForm
     *
     * @memberof ChangeProfile
     */
    setShowDeleteForm () {
        const newState = {
            ...this.state,
            showDeleteForm: !this.state.showDeleteForm
        }
        
        this.setState(newState)
    }
    
    /**
     * Sets the state for showing the changeCivilStatus form
     *
     * @memberof ChangeProfile
     */
    setShowChangeCivilStatusForm () {
        const newState = {
            ...this.state,
            showChangeCivilStatusForm: !this.state.showChangeCivilStatusForm
        }
        
        this.setState(newState)
        
    }

    

    render () {

        console.log(this.props.user)
        return (
            <div className={styles.wrapper}>
            <ChangeProfileSection showChangeForm={this.setShowChangeCivilStatusForm}>
                <ChangeCivilStatus civilStatusInfo={this.props.user} showForm={this.state.showChangeCivilStatusForm}/>
            </ ChangeProfileSection>

            <ChangeProfileSection showChangeForm={this.setShowDeleteForm}>
                <DeleteAccount showDeleteForm={this.state.showDeleteForm}/>
            </ ChangeProfileSection>
        </div>
        )
    }
}


const mapStateToProps = (state) => {
    console.log(state);
    return {
      user: {
        username: state.user.username,
        email: state.authentication.email || " ",
        firstName: state.authentication.firstName || " ",
        lastName: state.user.lastName || " ",
        workplace: state.user.workplace || " ",
        location: state.user.location || " ",
        interests: state.user.interests || " ",
        avatar: state.user.avatar || " ",
        recievedExtensionInfo: state.user.recievedExtensionInfo || " ",
        error: state.user.error || " ",
        loading: state.user.loading || " "
      }
    }
  }

export default connect(mapStateToProps, null)(ChangeProfile)


